import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Footer from "../../../components/footer";
import {ReactComponent as ReactLogo} from '../../../eclipse.svg';
import {URLAPI} from "../../../config";


const Families = (props) => {
    let history = useHistory();
    const families = useSelector((state) => state.family_module._get_all_family);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (families.data && families.data.datos) {
            setData(families.data.datos);
        }
    }, [families]);


    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    function create_family() {
        history.push(`/familia/crear`);
    }

    function update_family(id) {
        history.push(`/familia/editar/${id}`);
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Familias"
                            items={[{label: "Lista", link: "/familias"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="col-12 mb-2">
                                   <p className="type_text text_color text_inter text_32">Lista de familias disponibles en catálogo</p>
                                   <p className="type_text text_black text_inter text_16">Primero elige una familia</p>
                                   <div className="row">
                                        {
                                            permisos.filter(x => x.codigo === 'add_families').length > 0 && (
                                                <div className="col-md-3 col-sm-6 col-12 text-center mt-1">
                                                    <div className="border_card" onClick={() => {create_family()}}>
                                                        <div className="mt-1 mb-1">
                                                            <ReactLogo width={150} heght={150}/>
                                                        </div>
                                                        <p className="type_text text_black text_inter text_24">Nueva</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {data.map((item) => (
                                            <div className="col-md-3 col-sm-6 col-12 text-center mt-1">
                                                <div className="border_card" onClick={() => {update_family(item.id)}}>
                                                    {item.imagen ?
                                                        <div className="mt-1 mb-1">
                                                            <img
                                                                className="logo"
                                                                src={URLAPI + item.imagen}
                                                                alt="logo"
                                                                width={150}
                                                                height={150}
                                                            />
                                                        </div>
                                                    : 
                                                        <div className="mt-1 mb-1">
                                                            <ReactLogo width={150} heght={150}/>
                                                        </div>
                                                    }
                                                    <p className="type_text text_black text_inter text_24">{item.nombre}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Families;
