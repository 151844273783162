import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import SelectInput from "../../../components/select";
import {
    clear_status_category,
    UPDATE_CATEGORY_SUCCESS,
    update_category,
    get_all_category
} from "../../../redux/categories";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import {Switch, Space} from "antd";

const UpdateCategory = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const _get_all_category = useSelector((state) => state.category_module._get_all_category);
    const _update_category = useSelector((state) => state.category_module._update_category);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        nombre: "",
        id_familia: 0,
        activo: false,
        familia: "",
    });

    useEffect(() => {
        if (_get_all_category.data && _get_all_category.data.datos) {
            const category = _get_all_category.data.datos.filter((x) => x.id === parseInt(id));
            if (category.length > 0) {
                setForm({
                    nombre: category[0].nombre,
                    familia: category[0].familia,
                    activo: category[0].activo,
                });
            }
        }
    }, [_get_all_category, id]);
    
    useEffect(() => {
        if (_update_category.status === 200) {
            dispatch(clear_status_category(UPDATE_CATEGORY_SUCCESS));
            history.push("/categorias");
            dispatch(get_all_category(token));
        } else if (_update_category.status !== 0) {
            dispatch(clear_status_category(UPDATE_CATEGORY_SUCCESS));
            toast.error(_update_category.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_category, dispatch, history, token]);

    const [families, setFamilies] = useState([]);

    const _get_all_families = useSelector((state) => state.family_module._get_all_family);
    
    useEffect(() => {
        if (_get_all_families.data.datos) {
            let values = _get_all_families.data.datos.filter((x) => x.activo === true);
            setFamilies(values);
        }
    }, [_get_all_families]);


    const handleChange = (e) => {
        if (e.target.name === "id_familia") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleCheckedChange = (checked) => {
        setForm({
            ...form,
            activo: checked,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields

        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        
        if (form.id_familia === 0) {
            toast.error("Debe seleccionar una familia", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(update_category(token, id, form));
    };

    const [permisos, setPermisos] = useState([]);
    const [permissionUpdate, setPermissionUpdate] = useState(false);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);


    useEffect(() => {
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_categories').length > 0) {
                setPermissionUpdate(true);
            }
        }
    }, [permisos]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title={permissionUpdate ? 'Actualizar' : 'Ver'}
                            items={[
                                {label: "Lista", link: "/categorias"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        {!permissionUpdate ? (
                                            <div className="d-flex">
                                                <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {history.push(`/categorias`);}}>
                                                    <ReactCancel width={32} heght={29}/>
                                                </div>
                                                <p className="type_text text_color text_inter text_32">Ver categoría</p>
                                            </div>
                                        ) : (
                                                <>
                                                    <div className="d-flex">
                                                        <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {history.push(`/categorias`);}}>
                                                            <ReactCancel width={32} heght={29}/>
                                                        </div>
                                                        <p className="type_text text_color text_inter text_32">Actualizar categoría</p>
                                                    </div>
                                                    <p className="type_text text_black text_inter text_16">Editar o eliminar la categoría seleccionada</p>
                                                </>
                                        )}
                                        <form className="card" onSubmit={handleSubmit}>
                                            <div><ToastContainer/></div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input
                                                        label="nombre"
                                                        name="nombre"
                                                        value={form.nombre}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Familia</label>
                                                        <SelectInput
                                                            label="Familia"
                                                            name="id_familia"
                                                            value={form.id_familia}
                                                            select={form.familia}
                                                            onChange={handleChange}
                                                            options={families ? families.map((item) => ({
                                                                name: item.nombre,
                                                                id: item.id,
                                                            })) : []}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-1">
                                                    <Space size={14} direction='horizontal'>
                                                        <strong>Activo</strong>
                                                        <Switch
                                                            checked={form.activo}
                                                            checkedChildren="SI"
                                                            unCheckedChildren="NO"
                                                            onChange={handleCheckedChange}
                                                        />
                                                    </Space>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex justify-content-end">
                                                <Link to="/categorias" className="btn btn-outline-dark m-1">
                                                    Cancelar
                                                </Link>
                                                {permissionUpdate && (
                                                    <Button type="submit" text="Guardar" theme="calendar"/>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateCategory;
