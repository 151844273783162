import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LoadingSpinner from "../../../components/loading-spinner";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Input from "../../../components/input";
import SelectInput from "../../../components/select";
import { create_quotation, get_all_quotation, clear_status_quotation, CREATE_QUOTATION_SUCCESS } from "../../../redux/quotation";
import Modal
 from "../../../components/modal";
import { get_all_shopping_cart } from "../../../redux/shopping_carts";

const CartContact = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);

    const _get_all_city = useSelector((state) => state.city_module._get_all_city);

    const [form, setForm] = useState({
        id_usuario: '',
        identificacion: '',
        nombres: '',
        apellidos: '',
        correo: '',
        telefono: '',
        id_ciudad: 0,
        ciudad: '',
        direccion: '',
    });

    const [variants, setVariants] = useState([]);
    const [cities, setCities] = useState([]);
    const [modalConfirm, setModalConfirm] = useState(false);
    
    useEffect(() => {
        if (_get_all_city.data.datos) {
            let values = _get_all_city.data.datos.filter(x => x.activo === true);
            setCities(values);
        }
    }, [_get_all_city]);

    const handleChange = (e) => {
        if (e.target.name === "id_ciudad") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const _get_all_shopping_cart = useSelector((state) => state.shopping_cart_module._get_all_shopping_cart);

    useEffect(() => {
        if (_get_all_shopping_cart.data) {
            if (_get_all_shopping_cart.data.datos) {
                let vari = [];
                // eslint-disable-next-line
                _get_all_shopping_cart.data.datos.map((x) => {
                    vari.push({'id_variante': x.id_variante, 'id_detalle': x.id, 'cantidad': x.cantidad, 'id_quotation': x.id_quotation});
                })
                setVariants(vari)
            }
            if (_get_all_shopping_cart.data.usuario) {
                let data = _get_all_shopping_cart.data;
                setForm({
                    id_usuario: data.usuario.id,
                    identificacion: data.usuario.identificacion,
                    nombres: data.usuario.nombres,
                    apellidos: data.usuario.apellidos,
                    correo: data.usuario.correo,
                    telefono: data.usuario.telefono,
                    id_ciudad: data.datos[0].id_ciudad,
                    ciudad: data.datos[0].ciudad,
                    direccion: data.datos[0].direccion,
                })
            }
        }
    }, [_get_all_shopping_cart]);

    const _create_quotation = useSelector((state) => state.quotation_module._create_quotation);

    useEffect(() => {
        if (_create_quotation.status === 201) {
            dispatch(clear_status_quotation(CREATE_QUOTATION_SUCCESS));
            setModalConfirm(true);
            setIsLoading(false);
        } else if (_create_quotation.status !== 0) {
            dispatch(clear_status_quotation(CREATE_QUOTATION_SUCCESS));
            toast.error(_create_quotation.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_quotation, dispatch, history, token]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (form.identificacion === "") {
            toast.error("Debe ingresar la identificación", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.nombres === "") {
            toast.error("Debe ingresar los nombres", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar los apellidos", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.correo === "") {
            toast.error("Debe ingresar el correo electrónico", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.telefono === "") {
            toast.error("Debe ingresar el teléfono", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.id_ciudad === 0) {
            toast.error("Debe seleccionar una ciudad", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        if (form.direccion === "") {
            toast.error("Debe ingresar una dirección", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        const data = new FormData();
        data.append('id_usuario', form.id_usuario);
        data.append('id_ciudad', form.id_ciudad);
        data.append('direccion', form.direccion);
        data.append('variantes', JSON.stringify(variants));
        dispatch(create_quotation(token, data));
    };

    return (
        <div className="color box_shadow">
            <div className="p-1">
                <div className="card-body">
                    {isLoading && <LoadingSpinner/>}
                    <div><ToastContainer/></div>
                    <p className="type_text text_20 text_black">Carrito</p>
                    <p className="type_text text_16 text_black">Ayúdanos con tus datos para confirmar la cotización</p>
                    <div className="mt-2 row">
                        <div className="col-md-6 col-sm-12">
                            <p className="type_text text_20 text_black">Contacto</p>
                            <div className="border_card_1 p-1">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <Input
                                            label="Identificación"
                                            name="identificacion"
                                            value={form.identificacion}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <Input
                                            label="Nombres"
                                            name="nombres"
                                            value={form.nombres}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <Input
                                            label="Apellidos"
                                            name="apellidos"
                                            value={form.apellidos}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <Input
                                            label="Correo electrónico"
                                            name="correo"
                                            value={form.correo}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <Input
                                            label="Teléfono"
                                            name="telefono"
                                            value={form.telefono}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 p-1">
                            <p className="type_text text_20 text_black">Información de envío</p>
                            <div className="border_card_1 p-1">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Ciudad</label>
                                            <SelectInput
                                                label="Ciudad"
                                                name="id_ciudad"
                                                value={form.id_ciudad}
                                                select={form.ciudad}
                                                onChange={handleChange}
                                                options={cities ? cities.map((item) => ({
                                                    name: item.nombre,
                                                    id: item.id,
                                                })) : []}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <Input
                                            label="Dirección"
                                            name="direccion"
                                            value={form.direccion}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-right">
                    <button type="button" onClick={() => {
                        history.push(`/carrito-compra`);
                        document.getElementById("catalogo").className = "type_text text_black text_16";
                        document.getElementById("cotizaciones").className = "type_text text_black text_16";
                        document.getElementById("carrito").className = "type_text text_black text_16 active";
                        }} className={`btn btn-outline-calendar-cliente1`} style={{margin: 1}}>Regresar</button>
                    <button type="button" onClick={handleSubmit} className={`btn btn-calendar-cliente1`} style={{margin: 1}}>Continuar</button>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modalConfirm}>
                {isLoading && <LoadingSpinner/>}
                <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Cotización registrada</h5>
                    <div className="text-center">
                        <p className="type_text text_16 text_black">Se ha ingresado tu cotización a nuestro sistema un asesor te contestará a la mayor brevedad posible.</p>
                    </div>
                </div>
                <div className="card-footer text-center">
                    <button type="button" onClick={() => {
                            history.push(`/mis-cotizaciones`);
                            document.getElementById("catalogo").className = "type_text text_black text_16";
                            document.getElementById("cotizaciones").className = "type_text text_black text_16 active";
                            document.getElementById("fabricaciones").className = "type_text text_black text_16";
                            document.getElementById("carrito").className = "type_text text_black text_16";
                            dispatch(get_all_quotation(token));
                            dispatch(get_all_shopping_cart(token));
                            modalConfirm(false);
                        }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Entendido</button>
                </div>
            </Modal>
        </div>
    );
};

export default CartContact;
