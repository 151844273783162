import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Footer from "../../../components/footer";

const Attributes = (props) => {
    let history = useHistory();
    const attributes = useSelector((state) => state.attribute_module._get_all_attribute);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (attributes.data && attributes.data.datos) {
            setData(attributes.data.datos);
        }
    }, [attributes]);

    function createAttribute() {
        history.push(`/atributo/crear`);
    }

    function updateAttribute(id) {
        history.push(`/atributo/editar/${id}`);
    }

    const [permisos, setPermisos] = useState([]);
    const [update_Attribute, setUpdate_Attribute] = useState(false);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);


    useEffect(() => {
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_attributes').length > 0) {
                setUpdate_Attribute(true);
            }
        }
    }, [permisos]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title={update_Attribute ? 'Actualizar' : 'Ver'}
                            items={[{label: "Lista", link: "/atributos"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="col-12 mb-2">
                                    <p className="type_text text_color text_inter text_32">Lista de atributos disponibles en catálogo</p>
                                    <p className="type_text text_black text_inter text_16">Gestiona las categorías para el catálogo. Puedes crear, cambiar su nombre, inactivarlas o eliminarlas</p>
                                    <div className="row mt-2">
                                        {
                                            permisos.filter(x => x.codigo === 'add_attributes').length > 0 && (
                                                <div className="col-md-6 col-12">
                                                    <p onClick={() => {createAttribute()}} className="border_card_1 mr-1 text-center" style={{padding: 10, width: '90%', cursor: 'pointer'}}>Nuevo Atributo</p>
                                                </div>
                                            )
                                        }
                                        {data.map((x) => (
                                            <div className="col-md-6 col-12 d-flex text-center">
                                                <p className="border_card_1 mr-1" style={{padding: 10, width: '90%'}}>{x.nombre}</p>
                                                { permisos.filter(x => x.codigo === 'change_attributes').length > 0 && (
                                                    <>
                                                        {/* eslint-disable-next-line */}
                                                        <a href="#" onClick={() => {updateAttribute(x.id);}}>
                                                            <img
                                                                className="logo"
                                                                src="../../../base/app-assets/images/icon/update.png"
                                                                alt="logo"
                                                                width={40}
                                                                height={40}
                                                            />
                                                        </a>
                                                    </>
                                                )}
                                                
                                            </div>
                                        ))}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Attributes;
