import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    clear_status_attribute,
    UPDATE_ATTRIBUTE_SUCCESS,
    CREATE_ATTRIBUTE_VALUE_SUCCESS,
    update_attribute,
    get_all_attribute,
    create_attribute_value,
    delete_attribute_value,
    delete_attribute,
    DELETE_ATTRIBUTE_VALUE_SUCCESS,
    DELETE_ATTRIBUTE_SUCCESS
} from "../../../redux/attributes";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import Modal from "../../../components/modal";
import LoadingSpinner from '../../../components/loading-spinner';
import {ReactComponent as ReactCancel} from '../../../cancel.svg';


const UpdateAttribute = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const _get_all_attribute = useSelector((state) => state.attribute_module._get_all_attribute);
    const _update_attribute = useSelector((state) => state.attribute_module._update_attribute);
    const _delete_attribute = useSelector((state) => state.attribute_module._delete_attribute);
    const _create_attribute_value = useSelector((state) => state.attribute_module._create_attribute_value);
    const _delete_attribute_value = useSelector((state) => state.attribute_module._delete_attribute_value);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        nombre: "",
        valores: [],
    });

    useEffect(() => {
        if (_get_all_attribute.data && _get_all_attribute.data.datos) {
            const attribute = _get_all_attribute.data.datos.filter(
                (x) => x.id === parseInt(id)
            );
            if (attribute.length > 0) {
                setForm({
                    nombre: attribute[0].nombre,
                    valores: attribute[0].valores,
                });
            }
        }
    }, [_get_all_attribute, id]);
    
    useEffect(() => {
        if (_update_attribute.status === 200) {
            dispatch(clear_status_attribute(UPDATE_ATTRIBUTE_SUCCESS));
            history.push("/atributos");
            dispatch(get_all_attribute(token));
        } else if (_update_attribute.status !== 0) {
            dispatch(clear_status_attribute(UPDATE_ATTRIBUTE_SUCCESS));
            toast.error(_update_attribute.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_attribute, dispatch, history, token]);

    useEffect(() => {
        if (_delete_attribute.status === 200) {
            dispatch(clear_status_attribute(DELETE_ATTRIBUTE_SUCCESS));
            history.push(`/atributos`);
            dispatch(get_all_attribute(token));
            setIsLoading(false);
        } else if (_delete_attribute.status !== 0) {
            dispatch(clear_status_attribute(DELETE_ATTRIBUTE_SUCCESS));
            toast.error(_delete_attribute.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_delete_attribute, dispatch, history, token, id]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields

        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        
        dispatch(update_attribute(token, id, form));
    };

    const [permisos, setPermisos] = useState([]);
    const [permissionUpdate, setPermissionUpdate] = useState(false);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);


    useEffect(() => {
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_attributes').length > 0) {
                setPermissionUpdate(true);
            }
        }
    }, [permisos]);

    const [modal_create, setModalCreate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [value, setValue] = useState({
        nombre_valor: "",
        id_atributo: id,
    });

    const handleChangeValues = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (_create_attribute_value.status === 201) {
            dispatch(clear_status_attribute(CREATE_ATTRIBUTE_VALUE_SUCCESS));
            history.push(`/atributo/editar/${id}`);
            dispatch(get_all_attribute(token));
            setIsLoading(false);
            setModalCreate(false);
        } else if (_create_attribute_value.status !== 0) {
            dispatch(clear_status_attribute(CREATE_ATTRIBUTE_VALUE_SUCCESS));
            toast.error(_create_attribute_value.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_attribute_value, dispatch, history, token, id]);

    useEffect(() => {
        if (_delete_attribute_value.status === 200) {
            dispatch(clear_status_attribute(DELETE_ATTRIBUTE_VALUE_SUCCESS));
            history.push(`/atributo/editar/${id}`);
            dispatch(get_all_attribute(token));
            setIsLoading(false);
        } else if (_delete_attribute_value.status !== 0) {
            dispatch(clear_status_attribute(DELETE_ATTRIBUTE_VALUE_SUCCESS));
            toast.error(_delete_attribute_value.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_delete_attribute_value, dispatch, history, token, id]);

    const handleSubmitValue = () => {
        setIsLoading(true);
        if (value.nombre_valor === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        dispatch(create_attribute_value(token, value));
    };

    function deleteAttributeValue(id_attribute_value) {
        setIsLoading(true);
        dispatch(delete_attribute_value(token, id_attribute_value));
    }

    function deleteAttribute() {
        setIsLoading(true);
        dispatch(delete_attribute(token, id));
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title={permissionUpdate ? 'Actualizar' : 'Ver'}
                            items={[
                                {label: "Lista", link: "/atributos"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        {!permissionUpdate ? (
                                            <div className="d-flex">
                                                <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {history.push(`/atributos`);}}>
                                                    <ReactCancel width={32} heght={29}/>
                                                </div>
                                                <p className="type_text text_color text_inter text_32">Ver atributo</p>
                                            </div>
                                        ) : (
                                                <>
                                                    <div className="d-flex">
                                                        <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {history.push(`/atributos`);}}>
                                                            <ReactCancel width={32} heght={29}/>
                                                        </div>
                                                        <p className="type_text text_color text_inter text_32">Actualizar atributo</p>
                                                    </div>
                                                    <p className="type_text text_black text_inter text_16">Edita el atributo seleccionado y agrega sus valores</p>
                                                </>
                                        )}
                                        <form className="card" onSubmit={handleSubmit}>
                                            <div><ToastContainer/></div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Input
                                                        label="nombre"
                                                        name="nombre"
                                                        value={form.nombre}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <p className="type_text text_black text_inter text_16">Agrega valores al atributo</p>
                                                </div>
                                                <div className="col-md-6 col-12 d-flex justify-content-end">
                                                    <button type="button" onClick={() => {setModalCreate(true); setValue({...value, nombre_valor: ''})}} className={`btn btn-calendar`}>Agregar</button>
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                {form.valores.map((x) => (
                                                    <div className="col-md-3 col-sm-6 col-12 d-flex text-center">
                                                        <p className="border_card_1" style={{padding: 10, width: '90%'}}>{x.nombre}</p>
                                                        {/* eslint-disable-next-line */}
                                                        <a href="#" onClick={() => {deleteAttributeValue(x.id);}}>
                                                            <img
                                                                className="logo"
                                                                src="../../../base/app-assets/images/icon/delete.png"
                                                                alt="logo"
                                                                width={50}
                                                                height={50}
                                                            />
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="card-footer d-flex justify-content-end">
                                                <Link to="/atributos" className="btn btn-outline-dark m-1">
                                                    Cancelar
                                                </Link>
                                                {
                                                    permisos.filter(element => element.codigo === "delete_attributes").length > 0 && (
                                                        <button type="button" className="btn btn-danger mr-1" onClick={() => {deleteAttribute()}}>Eliminar</button>
                                                    )
                                                }
                                                {permissionUpdate && (
                                                    <Button type="submit" text="Guardar" theme="calendar"/>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <Modal className="modal-main div-modal" show={modal_create}>
                                {isLoading && <LoadingSpinner/>}
                                <div className="card-body">
                                    <h5 className="titulo_modal type_text mb-2">Crear valor</h5>
                                    <div className="form-group">
                                        <Input
                                            label="Nombre"
                                            name="nombre_valor"
                                            value={value.nombre_valor}
                                            onChange={handleChangeValues}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                    <button type="button" onClick={() => {handleSubmitValue()}} className={`btn btn-calendar mb-1 button_div`}>Crear</button>
                                    <button type="button" onClick={() => {setModalCreate(false);}} className={`btn btn-outline-calendar button_div`}>Cancelar</button>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateAttribute;
