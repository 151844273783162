import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import Footer from "../../../components/footer";

const Clients = (props) => {
    const users = useSelector(
        (state) => state.users_module._get_all_users
    );

    const [data, setData] = useState([]);

    useEffect(() => {
        if (users.data) {
            let values = users.data.filter(x => x.cliente===true)
            setData(values);
        }
    }, [users]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Clientes"
                            items={[{label: "Lista", link: "/clientes"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">

                                <div className="col-12 mb-2">
                                    <div className="table-responsive">

                                        <DataTable
                                            dataTable={data}
                                            columns={[
                                                {
                                                    name: "identificacion",
                                                    label: "Identificación",
                                                    type: "text",
                                                },
                                                {
                                                    name: "nombres",
                                                    label: "Nombres",
                                                    type: "text",
                                                },
                                                {
                                                    name: "apellidos",
                                                    label: "Apellidos",
                                                    type: "text",
                                                },
                                                {
                                                    name: "correo",
                                                    label: "Correo",
                                                    type: "text",
                                                },
                                                {
                                                    name: "activo",
                                                    label: "Activo",
                                                    type: "boolean",
                                                }
                                            ]}
                                            actions={[]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Clients;
