import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import LoadingSpinner from '../../../components/loading-spinner';
import CheckBox from "../../../components/check-box";
import {URLAPI} from "../../../config/index";
import {
    clear_status_banner_image, 
    create_banner_image, 
    CREATE_BANNER_IMAGE_SUCCESS, 
    get_all_banner_image, 
    update_banner_image, 
    UPDATE_BANNER_IMAGE_SUCCESS
} from "../../../redux/banner_images";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const BannerImages = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_banner_image = useSelector((state) => state.banner_image_module._get_all_banner_image);
    const _create_banner_image = useSelector((state) => state.banner_image_module._create_banner_image);
    const _update_banner_image = useSelector((state) => state.banner_image_module._update_banner_image);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (_get_all_banner_image.data.datos) {
            setData(_get_all_banner_image.data.datos);
        }
    }, [_get_all_banner_image]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(0);

    const [form, setForm] = useState({
        activo: "",
        imagen: ""
    });

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_bannerimages').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id);
                        setForm({...form, imagen: "", activo: item.activo});
                        setImagePreview(null);
                    },
                })
            }
        }
        setActions(act);
    // eslint-disable-next-line
    }, [permisos, history]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked,
        });
    };

    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    useEffect(() => {
        if (_create_banner_image.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_status_banner_image(CREATE_BANNER_IMAGE_SUCCESS));
            dispatch(get_all_banner_image(token));
            setIsLoading(false);
            setModal(false);
            setForm({
                activo: "",
                imagen: ""
            })
        } else if (_create_banner_image.status !== 0) {
            dispatch(clear_status_banner_image(CREATE_BANNER_IMAGE_SUCCESS));
            toast.error(_create_banner_image.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_banner_image, dispatch, history, token, id]);

    useEffect(() => {
        if (_update_banner_image.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_status_banner_image(UPDATE_BANNER_IMAGE_SUCCESS));
            dispatch(get_all_banner_image(token));
            setIsLoading(false);
            setModal(false);
            setForm({
                activo: "",
                imagen: ""
            })
            setId(0);
        } else if (_update_banner_image.status !== 0) {
            dispatch(clear_status_banner_image(UPDATE_BANNER_IMAGE_SUCCESS));
            toast.error(_update_banner_image.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_banner_image, dispatch, history, token, id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.imagen === "") {
            toast.error("Debe seleccionar una imagen", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        let datas = new FormData();
        datas.append(`imagen`, form.imagen);
        if (id === 0) {
            dispatch(create_banner_image(token, datas));
        } else {
            datas.append(`activo`, form.activo)
            dispatch(update_banner_image(token, id, datas));
        }
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Lista"
                            items={[{label: "Imágnes", link: "/imagenes"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_bannerimages').length > 0 ? 
                                    <Button type="button" text="Crear" theme="calendar" onClick={() => {
                                        setModal(true); 
                                        setForm({...form, imagen: ''}); 
                                        setImagePreview(null);
                                        setId(0);
                                    }}/> : null
                                }
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                {isLoading && <LoadingSpinner/>}
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>Imágenes</h5>
                    </div>
                    <div className="modal-body p-2">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <Input
                                    name="imagen"
                                    label="Imagen"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                {imagePreview &&
                                    <div className="text-center">
                                        <img
                                            src={
                                                imagePreview == null
                                                    ? `${URLAPI}${form.imagen}`
                                                    : imagePreview
                                            }
                                            alt="imagen"
                                            width={100}
                                        />
                                    </div>
                                }
                                
                            </div>
                            {(id !== 0) &&
                                <div className="form-group">
                                    <CheckBox label="Activo" name="activo" id="activo" onChange={(e) => handleChange(e)} state={form.activo}/>
                                </div> 
                            }
                            <div className="text-center">
                                <button type="button" className="btn btn-outline-dark" onClick={() => {
                                    setModal(false);
                                    setId(null);
                                    setForm({
                                        descripcion: "",
                                        id: id,
                                    })
                                }}> Cancelar
                                </button>
                                <Button type="submit" text="Guardar" theme="btn btn-success" classes="m-1"/>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default BannerImages;
