import React, {useEffect, useState, useContext} from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import {useHistory} from "react-router-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import route from "./routes";
import {useSelector, useDispatch} from "react-redux";
import {get_all_users, get_all_groups, get_all_permisions, get_values_home_clients} from '../redux/users';
import {SocketContext, TYPE_REPORT_ORDER_GENERATE} from "../config/ws-client";
import {
    TYPE_UPDATE_MESSAGE,
    TYPE_REPORT_GENERATE,
    TYPE_CREATE_MESSAGE,
} from '../config/ws-client'
//import {toast} from "react-toastify";
import { get_all_family } from '../redux/families';
import { get_all_attribute } from "../redux/attributes";
import { get_all_category } from "../redux/categories";
import { get_all_gender } from "../redux/genders";
import { get_all_manufacturer } from "../redux/manufacturers";
import { get_all_city } from "../redux/cities";
import { get_all_items } from "../redux/items";
import Catalogue from "./auth/users/catalogue";
import Products from "./auth/users/catalogue_products";
import MyQuotes from "./auth/users/my_quotes";
import ItemQuote from "./auth/users/item_quote";
import Cart from "./auth/users/cart";
import CartContact from "./auth/users/cart_contact";
import Item from "./auth/users/item";
import { get_all_shopping_cart } from "../redux/shopping_carts";
import { 
    get_all_quotation, 
    get_all_quotation_admin, 
    save_notification, 
    check_export_status, 
    clear_status_quotation, 
    GET_REPORT, 
    get_values_home_quotations 
} from "../redux/quotation";
import { GET_REPORT_ORDER, check_export_status_order, clear_status_order, get_all_order, get_all_order_admin } from "../redux/order";
import { get_all_states } from "../redux/states";
import MyOrders from "./auth/users/my_orders";
import ItemOrder from "./auth/users/item_order";
import { get_all_bank_account } from "../redux/bank_accounts";
import { get_all_banner_image } from "../redux/banner_images";

const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _data_login = useSelector((state) => state.users_module.login);
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [permisos, setPermissions] = useState([]);
    const [is_client, setIsClient] = useState(false);
    const {socket} = useContext(SocketContext);
    const _get_notification = useSelector((state) => state.quotation_module._get_notification);
    /* const _get_notification_order = useSelector((state) => state.order_module._get_notification_order); */
    const [token, setToken] = useState("")

    const audio = new Audio("/base/assets/audio/audio.mp3");

    var day = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }
    var date = year + '-' + month + '-' + day;

    useEffect(() => {
        if (_data_login.status >= 200) {
            dispatch(get_all_users(_data_login.data.token));
            dispatch(get_all_groups(_data_login.data.token));
            dispatch(get_all_permisions(_data_login.data.token));
            dispatch(get_values_home_clients(_data_login.data.token));
            dispatch(get_all_family(_data_login.data.token));
            dispatch(get_all_attribute(_data_login.data.token));
            dispatch(get_all_category(_data_login.data.token));
            dispatch(get_all_gender(_data_login.data.token));
            dispatch(get_all_manufacturer(_data_login.data.token));
            dispatch(get_all_city(_data_login.data.token));
            dispatch(get_all_items(_data_login.data.token));
            dispatch(get_all_shopping_cart(_data_login.data.token));
            dispatch(get_all_quotation(_data_login.data.token, 1));
            dispatch(get_all_quotation_admin(_data_login.data.token, 1));
            dispatch(get_values_home_quotations(_data_login.data.token));
            dispatch(get_all_states(_data_login.data.token));
            dispatch(get_all_order(_data_login.data.token, 1));
            dispatch(get_all_order_admin(_data_login.data.token, 1));
            dispatch(get_all_bank_account(_data_login.data.token));
            dispatch(get_all_banner_image(_data_login.data.token));
            setToken(_data_login.data.token)
        } else {
            history.push("/");
        }

        if (JSON.stringify(_session_data.data) !== "{}") {
            if (_session_data.data.grupo_permiso) {
                if (_session_data.data.grupo_permiso.permisos) {
                    setPermissions(_session_data.data.grupo_permiso.permisos);
                    setIsClient(_session_data.data.cliente);
                }
            }
        }

    }, [dispatch, _data_login, _session_data, history, date]);


    useEffect(() => {
        handleMessageWs();
        // eslint-disable-next-line
    }, [socket])

    // const sleep = (ms) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }


    const handleMessageWs = () => {
        if (socket) {
            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === TYPE_UPDATE_MESSAGE) {
                    update_message(data, socket)
                    return;
                }

                if (data.type === TYPE_REPORT_GENERATE) {
                    report_generate(data, socket);
                }

                if (data.type === TYPE_CREATE_MESSAGE) {
                    create_message(data, socket)
                }
                
                if (data.type === TYPE_REPORT_ORDER_GENERATE) {
                    report_generate_order(data, socket);
                }
            }
        }
    }


    const update_message = (data, socket) => {
        /* toast.success(data.message);

        const notification = {
            title: data.title,
            message: data.message,
            result: null,
            id: data.id,
            read: false,
            type: TYPE_UPDATE_MESSAGE,
            show: true,
            date: data.date
        }
        let n = _get_notification.data ?? [];
        n.push(notification)*/
        //audio.play();
        dispatch(get_all_quotation(token));
    }

    const create_message = (data, socket) => {
        console.log('Crear')
        dispatch(get_all_quotation(token));
        dispatch(get_all_quotation_admin(token));
        dispatch(get_all_order(token));
        dispatch(get_all_order_admin(token));
        //audio.play();
    }

    let timer = null;

    const report_generate = (data, socket) => {
        timer = setInterval(() => {
            check_export_status(token, data.id_task).then(e => {
                let datos = e.data;
                if (e.status === 200) {
                    dispatch(clear_status_quotation(GET_REPORT))
                    const report = {
                        title: "Reporte Generado ",
                        message: datos.result.nombre,
                        result: datos.result,
                        id: datos.id_task,
                        read: false,
                        date: datos.date,
                        type: TYPE_REPORT_GENERATE,
                        show: true

                    }
                    let n = _get_notification.data ?? [];
                    n.push(report)
                    dispatch(save_notification(n));

                    audio.play();
                    clearInterval(timer)
                    timer = null;

                }
            }).catch(e => {
                clearInterval(timer)
                timer = null;
            })
        }, 5000)
    }

    const report_generate_order = (data, socket) => {
        timer = setInterval(() => {
            check_export_status_order(token, data.id_task).then(e => {
                let datos = e.data;
                if (e.status === 200) {
                    dispatch(clear_status_order(GET_REPORT_ORDER))
                    const report = {
                        title: "Reporte Generado ",
                        message: datos.result.nombre,
                        result: datos.result,
                        id: datos.id_task,
                        read: false,
                        date: datos.date,
                        type: TYPE_REPORT_ORDER_GENERATE,
                        show: true

                    }
                    let n = _get_notification.data ?? [];
                    n.push(report)
                    dispatch(save_notification(n));

                    audio.play();
                    clearInterval(timer)
                    timer = null;

                }
            }).catch(e => {
                clearInterval(timer)
                timer = null;
            })
        }, 5000)
    }

    return (
        <Router>
            <div className="header-navbar-shadow"/>
            <Navbar/>
            {!is_client ? (
               <>
                    <Sidebar/>
                    <Switch>
                        {/* eslint-disable-next-line*/}
                        {route.map((item, index) => {
                            if (!item.meta.requiresAuth) {
                                return (
                                    <Route path={item.path} exact={item.exact}>
                                        {<item.component {...permisos} />}
                                    </Route>
                                );
                            }

                            if (permisos.filter(x => item.meta.permission_required.includes(x.codigo)).length > 0) {
                                return (
                                    <Route path={item.path} exact={item.exact}>
                                        {<item.component {...permisos} />}
                                    </Route>
                                );
                            }
                        })}
                    </Switch>
                </>
            ) : (
                <>
                    <Switch>
                        <Route path="/" exact>
                            <Catalogue/>
                        </Route>
                        <Route path="/familia/:family" exact>
                            <Products/>
                        </Route>
                        <Route path="/ver/:id" exact>
                            <Item/>
                        </Route>
                        <Route path="/mis-cotizaciones" exact>
                            <MyQuotes/>
                        </Route>
                        <Route path="/mi-cotizacion/:id" exact>
                            <ItemQuote/>
                        </Route>
                        <Route path="/mis-fabricaciones" exact>
                            <MyOrders/>
                        </Route>
                        <Route path="/mi-fabricacion/:id" exact>
                            <ItemOrder/>
                        </Route>
                        <Route path="/carrito-compra" exact>
                            <Cart/>
                        </Route>
                        <Route path="/carrito-compra-contacto" exact>
                            <CartContact/>
                        </Route>
                    </Switch>
                </>
            )}
            
        </Router>
    );
};
export default Index;
