import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import LoadingSpinner from "../../../components/loading-spinner";
import {URLAPI} from "../../../config";
import {
    UPDATE_QUOTATION_SUCCESS,
    get_all_quotation_admin,
    update_quotation,
    clear_status_quotation,
    confirm_advance_payment_quotation,
    cancel_advance_payment_quotation, 
    CONFIRM_ADVANCE_PAYMENT_QUOTATION_SUCCESS,
    CANCEL_ADVANCE_PAYMENT_QUOTATION_SUCCESS
} from "../../../redux/quotation";
import { get_all_order, get_all_order_admin } from "../../../redux/order";
import Modal from "../../../components/modal";
import TextArea from "../../../components/text-area";

const UpdateQuotation = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();

    const _get_all_quotation_admin = useSelector((state) => state.quotation_module._get_all_quotation_admin);
    const _update_quotation = useSelector((state) => state.quotation_module._update_quotation);
    const _confirm_advance_payment_quotation = useSelector((state) => state.quotation_module._confirm_advance_payment_quotation);
    const _cancel_advance_payment_quotation = useSelector((state) => state.quotation_module._cancel_advance_payment_quotation);
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [observation, setObservation] = useState('');
    const [data, setData] = useState({
        fecha_creacion: "",
        codigo: "",
        estado: "",
        costo: 0,
        ciudad: "",
        direccion: "",
        usuario: {
            id: 0,
            identificacion: "",
            nombres: "",
            apellidos: "",
            correo: "",
            telefono: ""
        },
        detalles: []
    });

    const [config, setConfig] = useState({
        moneda: '',
        cantidad_decimal: '',
        separador_decimal: '',
    });

    const [form, setForm] = useState({
        costo: 0,
        total: 0,
    });

    useEffect(() => {
        if (_get_all_quotation_admin.data && _get_all_quotation_admin.data.datos) {
            const value = _get_all_quotation_admin.data.datos.filter(
                (x) => x.id === parseInt(id)
            );
            console.log('value[0].costo')
            console.log(parseFloat(value[0].costo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`))
            if (value.length > 0) {
                setData(value[0]);
                if (value[0].estado !== 'En espera') {
                    let subtotal = 0;
                    // eslint-disable-next-line
                    value[0].detalles.map(a => {
                        subtotal += parseFloat(a.subtotal);
                    });
                    const total = parseFloat(subtotal) + parseFloat(value[0].costo);
                    
                    setForm({
                        costo: parseFloat(value[0].costo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`),
                        total: total.toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)
                    });
                }
            }
            setConfig(_get_all_quotation_admin.data.configuraciones);
        }
    }, [_get_all_quotation_admin, id, config]);

    useEffect(() => {
        if (_update_quotation.status === 200) {
            setIsLoading(false);
            dispatch(clear_status_quotation(UPDATE_QUOTATION_SUCCESS));
            history.push("/cotizaciones");
            dispatch(get_all_quotation_admin(token));
        } else if (_update_quotation.status !== 0) {
            setIsLoading(false);
            dispatch(clear_status_quotation(UPDATE_QUOTATION_SUCCESS));
            toast.error(_update_quotation.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_quotation, dispatch, history, token]);

    const handleChange = (e) => {
        let subtotal = 0;
        // eslint-disable-next-line
        data.detalles.map(a => {
            subtotal += parseFloat(a.subtotal);
        });

        if (e.target.value !== '') {
            const total = parseFloat(subtotal) + parseFloat(e.target.value);
            setForm({
                ...form,
                costo: e.target.value,
                total: total.toFixed(`${config.cantidad_decimal}`),
            });
        } else {
            let total = subtotal
            setForm({
                ...form,
                costo: e.target.value,
                total: total.toFixed(`${config.cantidad_decimal}`)
            })
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let valid = true;
        // eslint-disable-next-line
        data.detalles.map(a => {
            if (parseFloat(a.precio) <= 0) {
                toast.error("EL precio de " + a.descripcion + " debe ser mayor a 0", {position: toast.POSITION.TOP_RIGHT});
                valid = false;
                // eslint-disable-next-line
                return;
            }
        })
        if (valid === true) {
            let details = [];
            // eslint-disable-next-line
            data.detalles.map(a => {
                details.push({
                    id: a.id,
                    cantidad: a.cantidad,
                    precio: a.precio,
                    subtotal: a.subtotal,
                })
            });
            const results = new FormData();
            results.append('costo', form.costo);
            results.append('detalles', JSON.stringify(details));
            dispatch(update_quotation(token, id, results));
        } else {
            setIsLoading(false);
        }
    }

    function change_variant(e, id) {
        if (data.detalles.length > 0) {
            let subtotal = 0;
            // eslint-disable-next-line
            data.detalles.map(a => {
                if (parseInt(a.id) === id) {
                    if (e.target.value !== '') {
                        a.precio = parseFloat(e.target.value);
                        a.subtotal = (parseFloat(e.target.value) * parseFloat(a.cantidad)).toFixed(`${config.cantidad_decimal}`);
                        a.subtotal1 = (parseFloat(e.target.value) * parseFloat(a.cantidad)).toFixed(`${config.cantidad_decimal}`);
                    } else {
                        a.precio = e.target.value;
                        a.subtotal = 0;
                        a.subtotal1 = 0;
                    }
                    setData({...data});
                }
                subtotal += parseFloat(a.subtotal);
            });
            let total = parseFloat(form.costo) + subtotal;
            setForm({...form, total: total.toFixed(`${config.cantidad_decimal}`)});
        }
    }

    useEffect(() => {
        if (_confirm_advance_payment_quotation.status === 200) {
            setIsLoading(false);
            dispatch(clear_status_quotation(CONFIRM_ADVANCE_PAYMENT_QUOTATION_SUCCESS));
            history.push("/cotizaciones");
            dispatch(get_all_quotation_admin(token));
            dispatch(get_all_order_admin(token));
            dispatch(get_all_order(token));
        } else if (_confirm_advance_payment_quotation.status !== 0) {
            setIsLoading(false);
            dispatch(clear_status_quotation(UPDATE_QUOTATION_SUCCESS));
            toast.error(_confirm_advance_payment_quotation.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_confirm_advance_payment_quotation, dispatch, history, token]);

    useEffect(() => {
        if (_cancel_advance_payment_quotation.status === 200) {
            setIsLoading(false);
            dispatch(clear_status_quotation(CANCEL_ADVANCE_PAYMENT_QUOTATION_SUCCESS));
            history.push("/cotizaciones");
            dispatch(get_all_quotation_admin(token));
        } else if (_cancel_advance_payment_quotation.status !== 0) {
            setIsLoading(false);
            dispatch(clear_status_quotation(CANCEL_ADVANCE_PAYMENT_QUOTATION_SUCCESS));
            toast.error(_cancel_advance_payment_quotation.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_cancel_advance_payment_quotation, dispatch, history, token]);

    const handleSubmitObservation = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (observation === "") {
            toast.error("Debe ingresar una observación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(cancel_advance_payment_quotation(token, id, {observacion: observation}));
    }

    return (
        <>
            {isLoading && <LoadingSpinner/>}
            <div className="app-content content">
                <div className="content-overlay"/>
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-12 mb-2 mt-3">
                            <Breadcrumbs
                                title="Cotizaciones"
                                items={[
                                    {label: `${data.codigo}`, link: `/cotizaciones`},
                                ]}
                            />
                        </div>
                    </div>
                    <div className="content-body">
                        <div><ToastContainer/></div>
                        <form className="card pl-5 pr-5" onSubmit={handleSubmit}>
                            <div className="card-body">
                                {(data.codigo !== '') &&
                                    <>
                                        <div className="ml-1">
                                            <div className="d-flex">
                                                <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {history.push(`/cotizaciones`);}}>
                                                    <ReactCancel width={32} heght={29}/>
                                                </div>
                                                <p className="ml-2 type_text text_color text_inter text_32">{data.codigo_interno !== '' && data.codigo_interno}</p>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-6 col-sm-12">
                                                <p className="type_text text_black text_inter text_16">Cliente: {data.usuario.nombres + ' ' + data.usuario.apellidos}</p>
                                                <p className="type_text text_black text_inter text_16">Ciudad: {data.ciudad}</p>
                                                <p className="type_text text_black text_inter text_16">Dirección: {data.direccion}</p>
                                            </div>
                                            <div className="col-md-6 col-sm-12 text-right">
                                                <p className="type_text text_black text_inter text_16">Fecha de creación: {data.fecha_creacion}</p>
                                                <p className="type_text text_black text_inter text_16">Estado: {data.estado}</p>
                                            </div>
                                        </div>
                                        {(data.detalles.length > 0) && (
                                            data.detalles.map((x) => (
                                                <div className="border_card_1 p-1 mb-1">
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-12">
                                                            {(x.imagenes.length > 0) &&
                                                                <img
                                                                    src={URLAPI + x.imagenes[0].url}
                                                                    width={'60%'}
                                                                    height={380}
                                                                    alt='Imagen'
                                                                />
                                                            }
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <p className="type_text text_32 text_color">{x.titulo}</p>
                                                            <p className="type_text text_16 text_black">{x.descripcion}</p>
                                                            <p className="type_text text_16 text_black mb-2">Código: {x.codigo}</p>
                                                            {(x.valores) && x.valores.map((y) => (
                                                                <div className="mt-1">
                                                                    <p className="type_text text_14 text_black mb-0">{y.atributo}</p>
                                                                    <p className="type_text text_12 text_black div_detail" style={{padding: '7px 10px'}}>{y.valor}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <Input
                                                                label="Cantidad"
                                                                type="number"
                                                                name="precio"
                                                                value={x.cantidad}
                                                                disabled={true}
                                                                color_label="texto2"
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <Input
                                                                label="Precio unitario"
                                                                type="number"
                                                                color_label="texto2"
                                                                value={x.precio}
                                                                onChange={(e) => {change_variant(e, x.id)}}
                                                                disabled={data.estado !== 'En espera' ? true : false}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <Input
                                                                label="Subtotal"
                                                                value={x.subtotal1}
                                                                disabled={true}
                                                                color_label="texto2"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>                                   
                                            ))
                                        )}
                                        <div className="border_card_1 p-1 mt-1">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <p className="ml-2 type_text text_color text_inter text_32 mt-2">Total</p>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <Input
                                                        label="Costo de envío"
                                                        type={data.estado !== 'En espera' ? 'text' : 'number'}
                                                        name="costo"
                                                        value={data.estado !== 'En espera' ? config.moneda + ' ' + form.costo.toString() : form.costo}
                                                        onChange={handleChange}
                                                        color_label="texto2"
                                                        disabled={data.estado !== 'En espera' ? true : false}
                                                    />
                                                    {(data.anticipo) && (
                                                        <Input
                                                            label="Anticipo"
                                                            name="anticipo"
                                                            value={config.moneda + ' ' + data.anticipo.toString()}
                                                            disabled={true}
                                                            color_label="texto2"
                                                        />
                                                    )}
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <Input
                                                        label="Total"
                                                        name="total"
                                                        value={config.moneda + ' ' + form.total.toString()}
                                                        disabled={true}
                                                        color_label="texto2"
                                                    />
                                                    {/* eslint-disable-next-line */}
                                                    <p className="type_text text_16 text_black mt-3">{(data.archivo_anticipo) && (<a href={URLAPI + data.archivo_anticipo} target="_blank" style={{textDecoration: 'underline'}}>Ver comprobante</a>)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <Link to={`/cotizaciones`} className="btn btn-outline-dark m-1">
                                    Cancelar
                                </Link>
                                {data.estado === 'En espera' &&
                                    <Button type="submit" text="Guardar" theme="calendar"/>
                                }
                                {data.estado === 'Revisión pago' &&
                                    <>
                                        <button type="button" className="btn btn-outline-calendar mr-1" onClick={() => {
                                            setModal(true);
                                        }}>Notificar pago incompleto</button>
                                        <button type="button" className="btn btn-calendar" onClick={() => {
                                            setIsLoading(true);
                                            dispatch(confirm_advance_payment_quotation(token, id, {}));
                                        }}>Aprobar pago</button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <Footer/>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleSubmitObservation}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Anulación de pago</h5>
                        <div className="form-group">
                            <TextArea
                                id="observacion"
                                name="observacion"
                                label="Observación"
                                placeholder=""
                                onChange={(e) => {setObservation(e.target.value)}}
                                rows={4}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button className={`btn btn-calendar mb-1 button_div`}>Guardar</button>
                        <button type="button" onClick={() => {setModal(false);}} className={`btn btn-outline-calendar button_div`}>Cancelar</button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default UpdateQuotation;
